<template>
  <component
    :is="caption ? 'figure' : 'div'"
    :class="[
      'c-block__image',
      align,
      caption && 'c-block__figure',
      className && className,
    ]"
  >
    <SanityImage
      v-bind="{
        image: image.image || image,
        width,
        height,
        alt: image.alt,
        lqip,
        fit,
      }"
    />
    <figcaption v-if="caption">
      {{ caption }}
    </figcaption>
  </component>
</template>

<script>
import SanityImage from "./SanityImage";

export default {
  name: "PortableTextImage",
  components: {
    SanityImage,
  },
  props: {
    image: {
      type: Object,
      required: true,
      default() {
        return {
          alt: "Missing alternative text",
        };
      },
    },
    caption: {
      type: String,
      default: null,
    },
    alignment: {
      type: String,
      default: "none",
    },
    fit: {
      default: "max",
      type: String,
    },
    width: {
      type: Number,
      default: 220,
    },
    height: {
      type: Number,
    },
    className: {
      type: String,
    },
    lqip: {
      type: String,
    },
  },
  computed: {
    align() {
      if (this.alignment === "none") return;
      return `u-float--${this.alignment}`;
    },
  },
};
</script>

<style lang="scss">
figure.c-block__image {
  margin: 0;
}

figure.c-block__image,
div.c-block__image {
  margin-top: 2rem;
  margin-bottom: 2rem;
  &.u-float--right {
    margin: 5px 0 5px 20px;
    float: right;
  }
  &.u-float--left {
    float: left;
    margin: 5px 20px 5px 0;
  }

  figcaption {
    margin-top: 0.5rem;
    text-align: center;
  }
}
</style>
