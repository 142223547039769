<template>
  <div><SanityBlocks :blocks="content" :serializers="serializers" /></div>
</template>

<script>
import { SanityBlocks } from "sanity-blocks-vue-component";
import PortableTextImage from "./PortableTextImage.vue";
import Link from "./Link.vue";
export default {
  components: { SanityBlocks },
  props: {
    content: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup() {
    const serializers = {
      types: {
        blockImage: PortableTextImage,
      },
      marks: {
        externalLink: Link,
      },
    };
    return { serializers };
  },
};
</script>
