<template>
  <a :href="url" rel="noopener noreferrer"><slot /></a>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
    },
  },
};
</script>
